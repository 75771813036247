// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Montserrat';
  --ion-color-primary: #0072f0;
  --ion-color-secondary: #CB0108;
  --ion-color-success: #28a745;
  --ion-color-warning: #ffc107;
  --ion-color-danger: #CB0108;

  --ion-color-yellow: #ffd914;
  --ion-color-yellow-rgb: 255,217,20;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0,0,0;
  --ion-color-yellow-shade: #e0bf12;
  --ion-color-yellow-tint: #ffdd2c;

  .ion-color-yellow {
    --ion-color-base: var(--ion-color-yellow);
    --ion-color-base-rgb: var(--ion-color-yellow-rgb);
    --ion-color-contrast: var(--ion-color-yellow-contrast);
    --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
    --ion-color-shade: var(--ion-color-yellow-shade);
    --ion-color-tint: var(--ion-color-yellow-tint);
  }

  /** primary **/
  .ion-color-primary {
    --ion-color-primary: #0072f0;
    --ion-color-primary-rgb: 0, 114, 240;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0064d3;
    --ion-color-primary-tint: #1a80f2;
  }

  /** secondary **/
  .ion-color-secondary, .ion-color-danger {
    --ion-color-secondary: #CB0108;
    --ion-color-secondary-rgb: 203, 1, 8;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #b30107;
    --ion-color-secondary-tint: #d01a21;
  }

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  .ion-color-success {
    --ion-color-success: #28a745;
    --ion-color-success-rgb: 40, 167, 69;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #23933d;
    --ion-color-success-tint: #3eb058;
  }

  /** warning **/
  .ion-color-warning {
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0aa06;
    --ion-color-warning-tint: #ffc107;
  }

  /** danger **/
  //--ion-color-danger-rgb: 231,41,42;
  //--ion-color-danger-contrast: #ffffff;
  //--ion-color-danger-contrast-rgb: 255,255,255;
  //--ion-color-danger-shade: #cb2425;
  //--ion-color-danger-tint: #e93e3f;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.darkMode:not(.translucent) {
  --ion-text-color: #e4e4e4;

  /** warning **/
  .ion-color-warning {
    --ion-color-warning: #d09c00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0aa06;
    --ion-color-warning-tint: #ffc720;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, body, p {
    color: var(--ion-text-color);
  }

  ion-toolbar.transparent {
    .button-native, ion-button {
      color: var(--ion-text-color);
    }
  }
}

.darkMode.translucent {
  ion-toolbar.transparent {
    .button-native, ion-button {
      color: black;
      //color: var(--ion-color-darkest);
    }
  }
}
