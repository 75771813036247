// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "./theme/variables.scss";

// http://ionicframework.com/docs/v2/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
$blue: #0072F0;
$red: #f53d3d;
$green: green;
$micron-blue: #202AFF;

html {
  font-size: 62.5%; /* Now 10px = 1rem! */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


ion-icon.font-24 {
  font-size: 24px;
}

body {
  font-size: 16px; /* px fallback */
  font-size: 1.6rem; /* default font-size for document */
  line-height: 1.5; /* a nice line-height */
}

.medium-modal::part(content), ion-modal.medium-modal .modal-wrapper {
  height: 500px !important;
  background: transparent !important;
  ion-content {
    background: transparent;
  }
}

ion-button.pr-1::part(native) {
  padding-right: .5rem!important;
}


.delay-500 { animation-delay: 500ms !important; }
.delay-750 { animation-delay: 750ms !important; }
.delay-1250 { animation-delay: 1250ms !important; }


.ion-item-stretch {
  --padding-start: 0 !important;
}

.gkz-viewport {
  //height: 200px;
  //width: 200px;
  //border: 1px solid black;
  .cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: row;
  }
  .item:not(.standard) {
    height: 19px;
    border-bottom: 1px dashed #aaa;
  }
}

ion-content.loginPageBackground {
  &::part(main) {
    background: url('./assets/login.png') no-repeat center center / cover;
  }
}

ion-card.awaken {
  box-shadow: 0 7.5px 35px rgba(90, 97, 105, .2),
  0 15px 22.5px rgba(90, 97, 105, 0.2),
  0 4px 8.5px rgba(90, 97, 105, 0.24),
  0 2px 3px rgba(90, 97, 105, 0.2) !important;
  border-radius: 16px !important;

  &.google-pop-up-shadow {
    box-shadow: 0 24px 38px  3px rgba(0,0,0,0.14),
    0  9px 46px  8px rgba(0,0,0,0.12),
    0 11px 15px -7px rgba(0,0,0,0.2) !important;
  }
}

ion-toolbar.translucent-toolbar {
  backdrop-filter: saturate(180%) blur(20px);
  ion-title {
    --ion-color-base: var(--ion-color-contrast) !important;
  }
  ion-buttons > ion-button {
    --ion-color-base: var(--ion-color-contrast) !important;
  }
}

.modal-fullscreen::part(content), ion-modal.modal-fullscreen .modal-wrapper {
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.fabasic {
  font-family: FontAwesome !important;
}

.right-input {
  float: right;
  text-align: right;
}

[noScroll] {
  overflow: hidden;

  .scroll-content {
    overflow: hidden;
  }
}

table.table.awaken.weigh_ins {
  border-right: 1px solid #e9ecef;
  //border-left: 1px solid #e9ecef;
}

.bg-orange-fade {
  background-color: rgba(255, 165, 0, 0.25);
}

button.extra-small, button[extra-small] {
  margin: 0;
  .button-inner{ margin: 0; }
}

.strikethrough {
  text-decoration: line-through;
}

.fun-shadow {
  box-shadow:
          0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.35),
          0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.44),
          0 0.25rem 0.53125rem rgba(90, 97, 105, 0.62),
          0 0.125rem 0.1875rem rgba(90,97,105,.1)
}

.time-select {
  .select-icon { display: none; }

  ion-select {
    border: 1px solid dodgerblue !important;
    padding: 5px !important;
    height: 15px !important;
    width: 15px !important;
    margin: 5px !important;
    border-radius: 4px !important;
  }
}

ion-card-content {
  ion-list {
    button.item {
      border-bottom: 1px solid lightgray !important;

      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}


ionic2-datepicker .datepicker-wrapper {
  .weekday-header {
    background-color: darken(red, 10%) !important;
  }
  .datepicker-header {
    background-color: $red !important;

  }

  .calendar-wrapper .datepicker-current {
    background-color: transparentize($red, .5) !important;
    color: white !important;
  }
}

.card-ios form .item-ios.item-block ion-textarea {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-range.awaken {
  .range-tick {
    width: 2px;
    background: $red
  }
  .range-bar {
    height: 2px;
    background: $red;
  }
  .range-tick-active {
    background: $micron-blue;
  }
  .range-bar-active {
    background: $micron-blue;
  }
  .range-knob-handle {
    .range-knob {
      background: $micron-blue;
    }

    &.range-knob-pressed {
      .range-knob {
        box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.33), 0 0 0 1px rgba(0, 0, 0, 0.02);
      }
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

ion-toolbar {
  &.transparent {
    --background: 'transparent';
    --border-width: 0 0 0 0 !important;
  }
}

.alert-modal {
  .modal-wrapper {
    height: 350px !important;
    width: 100% !important;
    margin: 0 auto;
    position: relative;
    background: transparent;

    .real-wrapper {

      position: absolute;
      text-align: center;
      box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.5);
      background: white;
      top: 60px;
      border-radius: 1em;
      width: 50%;
      left: 25%;

      i.close,
      ion-icon.close {
        position: absolute;
        right: 15px;
        top: 5px;
        font-size: 36px;
      }

      h3, p {
        font-family: 'Quicksand'
      }

      h3 {
        font-weight: 600;
      }

      .title {
        margin-bottom: 0.5em;
      }

      .subtitle {
        // margin-bottom: 2em;
        color: #8B8B8B
      }
    }
  }
}

.alert-panel {
  width:50%;
  position:relative;
  //border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  background: white;
  box-shadow: 0px 5px 17px 1px rgba(0,0,0,0.5);
  max-width: 500px;

  i.close,
  ion-icon.close {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 36px;
  }

  h3,p { font-family: 'Quicksand' }
  h3 { font-weight: 600; }
  .title { margin-bottom: 0.5em; }
  .subtitle {
    // margin-bottom: 2em;
    color: #8B8B8B
  }
}

.dnd-drag-start {
  -moz-transform:scale(0.8);
  -webkit-transform:scale(0.8);
  transform:scale(0.8);
  opacity:0.7;
  border: 2px dashed #000;
}

.dnd-drag-enter {
  opacity:0.7;
  border: 2px dashed #000;
}

.dnd-drag-over {
  border: 2px dashed #000;
}

.dnd-sortable-drag {
  -moz-transform:scale(0.9);
  -webkit-transform:scale(0.9);
  transform:scale(0.9);
  opacity:0.7;
  border: 1px dashed #000;
}

.error-msg {
  color: red !important;
  font-size: 0.8em !important;
}

.repeat-infinite {
  animation-iteration-count: infinite;
}

.card-ios form {
  .item-ios.item-block {
    .item-inner {
      //border-bottom: 0.55px solid #c8c7cc;
    }
    ion-input, ion-select, ion-datetime {
      border-bottom: 0.55px solid #c8c7cc;
    }
  }
  .item-ios.item-block:last-of-type {
    .item-inner {
      border: 0
    }
  }
}

ion-item.flex {
  .label { display:flex; }

  &.space-around {
    .label { justify-content: space-around; }
  }
}

.fileUpload,
button.fileCaption {
  margin: 0.4rem 0.2rem;
  padding: 0 1em;
  height: 2.8em;
  border-radius: 4px;
  font-size: 1.6rem;
  color: #fff;
  background-color: #488aff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  &:focus,
  &:active,
  &:hover {
    // border-color: #aaa;
    background: darken(dodgerblue, 5%);
  }

  .upload {
    width: 100%;
    cursor: pointer;
  }

  span { cursor: pointer; }

  i { font-size: 24px;}
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  text-align: center;
}

.custom-span {
  font-weight: bold;
  font-size: 100px;
  color: $blue;
}

#uploadFile{
  border: none;
  margin-left: 10px;
  width: 200px;
}

.custom-para {
  // font-weight: bold;
  font-size: 24px;
  color:#585858;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    cursor: pointer;
  }

  &:focus + label,
  + label:hover {
    background-color: red;
  }

  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.no-margin { margin: 0; }
.image-container {
  width: 130px;
  height: 130px;
  display:flex;
  background: white;
  justify-content: center;
  align-items: center;
  margin: 0px auto 0 auto;
  border-radius: 100%;

  > .image {
    width: 110px;
    height: 110px;
    // background: dodgerblue;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      height: 65%;
      width: 100%;

      //animation: shake .25s ease-in-out;
      //animation-delay: 1s;
      //animation-iteration-count: 3;
    }
  }
}

.awaken-alert {
  * {
    font-family: 'Quicksand', sans-serif !important;
  }
  .alert-head {
    font-family: 'Quicksand', sans-serif;
  }
  .alert-wrapper {
    font-family: 'Quicksand', sans-serif;
    //width: 50%;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
    background: white;
    box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.5);
    //max-width: 500px;
  }

  .no-margin {
    margin: 0;
  }
  .image-container {
    width: 130px;
    height: 130px;
    display: flex;
    background: white;
    justify-content: center;
    align-items: center;
    margin: -60px auto 0 auto;
    border-radius: 100%;

    > .image {
      width: 110px;
      height: 110px;
      background: dodgerblue;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        height: 70%;
        width: 70%;

        //animation: shake .25s ease-in-out;
        //animation-delay: 1s;
        //animation-iteration-count: 3;
      }
    }
  }
}

ion-select { max-width: 100% !important; }

/* Path: */

.icon-md {
  font-size: 24px !important;
}

.icon-lg {
  font-size: 36px !important;
}

.icon-xl {
  font-size: 48px !important;
}

hr.awaken {
  background-color: rgba($blue, 0.3);
}


.employee-menu {
  .list-ios > .item-block:first-child {
    border-top: none;
  }
  .profile-container {
    display: flex;
    justify-content: center;
    height: 34%;
    flex-direction: column;

    background-image: linear-gradient(-180deg, #8840FF 20%, #1E90FF 100%);
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.50);
  }
  .initial-circle {
    border: 4px solid white;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 2em auto 1em auto;

    h1 {
      margin: 0;
      font-size: 2.5em;
    }
  }

  .menu-link-container {
    height: 66%;
    background: #e8e8e8;

    .item {
      background: #e8e8e8;
    }
  }

  ul.bio {
    padding: 0;
    margin: 0;
    color: white;
    list-style: none;
    text-align: center;
  }
}


.special-container {
  width: 100%;
  height: 100vh;
  font-family: 'Quicksand', sans-serif;
  display:flex;
  align-items: center;
  justify-content: center;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  25% {
    transform: rotate(10deg);
    transform-origin: 50% 50%;
  }
  75% {
    transform: rotate(-10deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}

@keyframes emphasis{
  0% { transform: scale(1); }
  25% { transform: scale(1.2); }
  75% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  33% { transform: rotate(-15deg); }
  66% { transform: rotate(15deg); }
  100% { transform: rotate(0deg); }
}

@keyframes emphasis_shake {
  0% {
    transform: rotate(0deg);
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  40% {
    transform: rotate(-15deg);
    transform: scale(1.2);
  }
  60% {
    transform: rotate(15deg);
    transform: scale(1.2);
  }
  75% {
    transform: rotate(0deg);
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.emphasis-shake {
  animation: 1s linear 0.25s 1 normal shake;
}


//  COLORS
$red: #e7292a;
$blue: dodgerblue;
$mdb-label-color: black;
$mdb-label-color-toggle-focus: darken($blue, 10%);
$mdb-checkbox-border-color: $blue;
$brand-primary: $blue;

#weigh-in-table {
  ion-col:first-of-type {
    // z-index: 999999;
  }
}

$text-input-highlight-color-valid: green;
$text-input-highlight-color-invalid: $red;



//ion-fab-list[side=top] {
//  button[ion-fab] {
//    overflow: visible;
//    position: relative;
//
//    ion-label {
//        position: absolute;
//        top: -8px;
//        right: 40px;
//
//        color: white;
//        background-color: rgba(0,0,0,0.7);
//        line-height: 24px;
//        padding: 4px 8px;
//        border-radius: 4px;
//    }
//  }
//}

.blue { color: $blue; }

.weigh-in-stat {
  height:48px !important;
  min-height:48px;
  max-height:48px;

  td {
    height:48px !important;
    min-height:48px;
    max-height:48px;
    padding: 0;
    vertical-align: middle;
  }
}

ion-select.ng-invalid {
  + .label { color: red; }
}

.has_error {
  color: red;
}

.no-scroll {
  .scroll-content {
    overflow-y: hidden;
  }
}

.white { color: white; }

.profile-form {
  ion-item > {
    i,ion-icon {
      font-size: 2em;
      color: $blue;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
      width: 25px;
    }
  }
  .item-block:first-child,
  .item-block:last-child {
    border: none;
  }

  ion-input { border-bottom: 0.55px solid #c8c7cc; }

  // &.ng-dirty {
  .ng-invalid {
    &.input {
      // border-bottom: 2px solid $red;
    }
  }
  // }

  .list-ios > .item-block:last-child .item-inner, .list-ios > .item-wrapper:last-child .item-block .item-inner {
    // border-bottom: 0.55px solid #c8c7cc;
  }
}

.toast-wrapper {
  background: dodgerblue !important;
  box-shadow: 0px 4px 12px -3px black;
}

tooltip {
  position: absolute;
  //top: 0;
  //left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
}

.reactive.error-msg {

}

.pink-yellow-gradient {
  background-image: linear-gradient(-135deg, #FF10BC 0%, #FFA306 97%);
}

.icon-shadow { text-shadow: 0 2px 2px rgba(0, 0, 0, .5) !important }


//[border-bottom-blue] { border-bottom: 1px solid $blue }

.popover {
  //min-width: 211px;
  //min-height: 80px;
}

ion-input.invalid {
  border-bottom: 2px solid $red !important;
}

.card-ios form .item-ios.item-block.better-validation.invalid ion-input,
.card-ios form .item-ios.item-block.better-validation.invalid ion-select,
.card-ios form .item-ios.item-block.better-validation.invalid ion-datetime {
  border-bottom: 2px solid $red !important;
}

ion-navbar[transparent] {
  button {
    color: black;
  }

  &.white {
    button {
      color: white;
    }
  }
}

.black {
  color: black !important;
}

.fw-500 {
  font-weight: 500;
}

.bright-blue {
  color: #202AFF;
}

.dope-blue {
  color: #007bff;
}

.bg-bright-blue {
  background: #202AFF;
}

.dodgerblue {
  color: dodgerblue;
}

.hidden {
  background: transparent;
  background-image: none !important;
}

form.ng-dirty {
  .ng-invalid {
    > .item-inner > .input-wrapper > .label {
      //color: $red !important;
    }
  }

  ion-input, ion-textarea {
    &.ng-invalid:not(.better-validation) {
      border-bottom: 2px solid $red !important;

      + .label {
        color: $red;
      }
    }
  }

  ion-item:not(.better-validation) {
    &.ng-invalid {
      &.color-icon-by-validity {
        ion-icon {
          color: $red;
        }
      }

      > .item-inner {
        border-bottom: 2px solid $red !important;
        ion-input { border: none !important; }
      }

    }

    &.ng-valid {
      &.color-icon-by-validity {
        ion-icon {
          color: $blue;
        }
      }
    }
  }

  ion-item.invalid > .item-inner {
    border-bottom: 2px solid $red;
  }
}

.btn-group > .btn {
  margin-right: 0;
  padding: 1em;

  &.active {
    color: white;
    background: dodgerblue;
  }
}


//.newRepoForm.ng-touched {
//
//  .ng-invalid > .item-inner,
//  ion-datetime.ng-invalid {
//    border-bottom: 2px solid $red;
//    border-width: 2px !important;
//  }
//
//  ion-datetime.ng-invalid + .label {
//    color: $red;
//  }
//}

.green { color: green; }
.red { color: $red; }

.label-ios[floating] {
  color: dodgerblue;
}

.awaken-chip {
  background: dodgerblue;

  > span {
    margin:0 10px;
    color:white;
    font-size:inherit;
  }
}

.google-pop-up-shadow {
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.bg-white {
  background: white;
}

.warning { color: #ff9800; }
.success { color: green; }
.danger { color: $red !important; }
.bg-danger {
  background: $red;
  color: white;
}
.bg-success {
  background: green !important;
  color: white;
}

.text-white {
  color: white !important;
}

.danger-gradient {
  background-color: red;
  display: flex;
  width:100%;
  line-height:2.5em;
  border-radius: 5px;
  justify-content:center;
  align-items:center;
  color:white;
  background-image:
          radial-gradient(
                          circle to left bottom,
                          darken($red, 20%), $red
          );
  i { font-size:1.5em; padding-right:1em; }
}
.warning-gradient {
  background-color: orange;
  display: flex;
  width:100%;
  line-height:2.5em;
  border-radius: 5px;
  justify-content:center;
  align-items:center;
  color:white;
  background-image:
          radial-gradient(
                          circle to left bottom,
                          darken(orange, 20%), orange
          );
  i { font-size:1.5em; padding-right:1em; }
}
.success-gradient {
  background-color: green;
  display: flex;
  width:100%;
  line-height:2.5em;
  border-radius: 5px;
  justify-content:center;
  align-items:center;
  color:white;
  background-image: radial-gradient(circle to left bottom, saturate(green, 10%), green);
  i { font-size:1.5em; padding-right:1em; }
}

.back-button.show-back-button {
  display: inline-block !important;
}

@keyframes shake {
  0% { margin-left: 0px; }
  25% { margin-left: -20px; }
  75% { margin-left: 20px; }
  100% { margin-left: 0px; }
}

.red-purple-gradient {
  background-image: linear-gradient(-137deg, #FF3030 13%, #561EFF 100%);
}

.awaken-select {
  width: 100%;
  max-width: 100%;
  font-size:12px;
  border: 1px solid dodgerblue;
  border-radius: 5px;
}

.shake-it {
  animation-name: shake;
  animation-iteration-count: 2;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

.awaken-title {
  .toolbar-title {
    font-size:20px;font-weight:400;
  }
}

.hidden-content {
  //display: none;
  max-height: 0;
  transition: max-height 500ms ease-out;

  &.show-content {
    //display:flex;
    max-height: 360px;
    transition: max-height 500ms ease-out;
  }
}

// ion-tab {
//   display: block !important;
//   opacity: 0;
//   transition: opacity 0.2s ease-out;
// }

// ion-tab.show-tab {

//   opacity: 1;
//   // transition: opactiy 0.2s ease-in;
// }

ion-navbar.show-back {
  .back-button { display: inline-block !important; }
}


.modal-wrapper {
  z-index: 10 !important;
  //height: 100% !important;
  contain: strict !important;
  top: 0 !important;
}

.small-modal::part(content), ion-modal.small-modal .modal-wrapper {
  height: 330px !important;
  background: transparent !important;
  ion-content {
    background: transparent;
  }
}

ion-modal.calendar-modal {
  --backdrop-opacity: 0.4 !important;
}

.calendar-modal::part(content), ion-modal.calendar-modal .modal-wrapper {
  height: 350px;
  width: 350px;


  .modal-shadow {
    text-align: center;
  }
}

.grid-sizer { width: 8.33%; }
.brick {
  // width: 8.33%;

  @for $i from 1 to 13 {
    &.b-col-#{$i} {
      width: 100% / ( 12 / $i );
    }
  }
  // width: 400px;
}


.grid-container {
  display: grid;
  grid-gap: 10px 10px;

  @for $i from 1 to 13 {
    &.g-col-#{$i} {
      grid-template-columns: repeat($i, 1fr);
      grid-template-rows: 4;
    }
  }

  $sections: profile comments weightloss graph1 weeks graph2 finance graph3;


  .g-card {
    justify-content: stretch;

    @each $section in $sections {
      &.#{$section} { grid-area: #{$section}; }
    }
  }
}

.list-no-lines {
  ion-input, ion-select {
    //border-bottom: 1px solid #ddd;
  }

  ion-item > .item-inner {
    border-bottom: 0.55px solid #c8c7cc;
  }
}

.ion-input {
  width:100%;
  border: none;
  border-bottom: .55px solid #c8c7cc;
  line-height: 3em;
}

.fullscreen-modal .modal-wrapper {
  width: 100%;
  border-radius: 0px;
}

ion-item[no-border] {
  .item-inner {
    border: 0;
    border-bottom: none !important;
  }
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.bd-callout.bd-callout-primary {
  border-color: $blue;
  h4 {
    color: $blue;
  }
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem
}

.bd-callout p:last-child {
  margin-bottom: 0
}

.bd-callout code {
  border-radius: .25rem
}

.bd-callout+.bd-callout {
  margin-top: -.25rem
}

.bd-callout-info {
  border-left-color: #5bc0de
}

.bd-callout-info h4 {
  color: #5bc0de
}

.bd-callout-warning {
  border-left-color: #f0ad4e
}

.bd-callout-warning h4 {
  color: #f0ad4e
}

.bd-callout-danger {
  border-left-color: $red
}

.bd-callout-danger h4 {
  color: #d9534f
}

form.ng-dirty {
  input.ng-invalid,
  ion-select.ng-invalid {
    border-bottom: 2px solid $red !important;
  }
}

// .ng-invalid {
//   color: $red !important;

//   + .label {
//     color: $red !important;
//   }
// }

.invalid_field {
  color: $red !important;

  i, ion-icon {
    color: $red !important;
  }

  .value {
    color: #aaa;
    font-style: italic;
  }
}




.unlock-screen {
  .unlock-row:nth-child(even) {
    margin: 25px 0;
  }
}

.hide-the-tabs {
  .tabbar {
    bottom: -56px !important;
  }
  .scroll-content {
    margin-bottom: 0px !important;
  }
}

.tight-cards {
  .card {
    margin: 0 !important;
    width: 100% !important;
    min-height:165px;
  }
  .row {
    margin: 0 !important;
    width: 100% !important;
  }
}

.scroll-x {
  overflow-x: scroll;
}

.flex {
  .right { text-align: right;}
  .left { text-align: left;}
  .center { text-align: center;}

  &.share {
    .flex-item { flex: 1; }
  }

  &.space-around {
    justify-content: space-around;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.column {
    flex-direction: column;
  }

  &.wrap { flex-wrap: wrap; }
}

.card {
  &.card-danger {
    border-color: #ebccd1;

    &> ion-card-header {
      border-color: #ebccd1;

      ion-toolbar {
        > .toolbar-background {
          background-color: #f2dede;
          //border-color: #ebccd1;
        }
      }

      ion-title {
        > .toolbar-title {
          color: #a94442;
        }
      }
    }
  }

  &.card-success {
    border-color: #d6e9c6;

    > ion-card-header {
      border-color: #d6e9c6;

      > ion-toolbar {
        > .toolbar-background.toolbar-background-ios {
          background-color: #dff0d8;
        }
      }

      ion-title {
        > .toolbar-title {
          color: #3c763d;
        }
      }
    }
  }
}

ion-note.delta-measurement {
  align-items: center;
  justify-content: space-between;
  display: flex;

  small {
    padding: 0 3px;
  }
}

.swiper-pagination-bullet {
  transition: all 200ms ease-in-out;
  background: black;
  opacity: 0.2;

  &.pass {
    background: green !important;
    opacity: 1 !important;
  }

  &.fail {
    background: red !important;
    opacity: 1 !important;
  }

  &.swiper-pagination-bullet-active {
    transform: scale(1.3);
    transition: all 200ms ease-in-out;
  }
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);

  &.panel-danger {
    border-color: #ebccd1;

    > .panel-heading {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
    }
  }
  .panel-title {
    font-size: 18px;
    font-weight: 500;
    padding: 0.5em;
  }
  .panel-body {
    padding: 0.5em;
  }
}




.card {
  box-shadow: 0px 3px 12px -4px rgba(0,0,0,0.75) !important;
  // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  // box-shadow: 0 0px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px !important;
}

.blue-gradient {
  background-image:
          linear-gradient(
                          to bottom right,
                          dodgerblue, lighten(dodgerblue, 20%)
          );
  --background:
          linear-gradient(
                          to bottom right,
                          dodgerblue, lighten(dodgerblue, 20%)
          );
}

.purple-gradient {
  background-image:
          linear-gradient(
                          to bottom right,
                          purple, lighten(orange, 30%)
          );
}


.awaken.date {
  font-size: 1em;
  position: absolute;
  right: 5px;
  top: 5px
}


ion-card-header[header-success] {
  background-color: #dff0d8;
  border-color: #3c763d;
}
ion-card-header[header-info] {
  background-color: #d9edf7;
  border-color: #31708f;
}
ion-card-header[header-warning] {
  background-color: #ffcdab;
  border-color: #ffa100;
}
ion-card-header[header-danger] {
  background-color: #f2dede;
  border-color: #a94442;
}

ion-list.awaken {
  p { color: black; }

  .date {
    font-size: 0.8em;
    position: absolute;
    right: 0;
    top: 0
  }

  ion-label {
    white-space: normal;
    padding:1em;
  }

  &.bordered {
    .item {
      border-bottom: 1px solid lighten($red, 25%);

      &:first-of-type {
        border-top:none;
      }
      &:last-of-type {
        border: none;
      }
    }
  }
}

ion-item.ng-invalid {
  //border-bottom: 0.55px solid red;
}

.button .button-inner {
  text-transform: none;
}

.no-transform { text-transform: none; }

.red-gradient {
  background-image:
          linear-gradient(
                          to bottom right,
                          $red, lighten($red, 50%)
          );
}

.rounded {
  border-radius: 1em !important;

  &.half {
    border-radius:0.5em !important;
  }
}

.introjs-helperLayer {
  background: transparent;
}

.introjs-overlay {
  display: none;
}

.introjs-helperLayer:before {
  opacity: 0;
  content: '';
  position: fixed;
  width: inherit;
  height: inherit;
  border-radius: 0.5em;
  border: .2em solid rgba(255, 217, 68, 0.8);
  box-shadow: 0 0 0 1000em rgba(0,0,0, .7);
  opacity: 1;
}

.introjs-helperLayer:after {
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

.butts {
  //opacity: 0;
}

.box-shadow {
  box-shadow: 0 0px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)
}

.awaken-header {
  // background: #ddd;
  border-top:none;
  border-bottom: 2px solid dodgerblue;
  position:sticky;
  text-align: center;
  margin-bottom: 0;
  color: $red;
  //text-transform: none;

  .input-wrapper { font-size: 20px; }
}

.validations {
  min-height: 24px;
  .toolbar-title {
    font-size: 12px;
  }
}

.awaken-striped {
  ion-item.item {
    background: white;
  }
}

.scale-0 {
  transform: scale(0);
  transition: transform linear .2s;
}

.scale-1 {
  transform: scale(1);
  transition: transform linear .2s;
}



.stat-icon {
  color: dodgerblue;
}

.stat-stat {
  font-size: 48px;
  color: black;
}

.supersize { font-size: 72px !important; }

.subtle {
  color: #aaa;
}

.flex {
  display: flex;

  &.column {
    flex-direction:column;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.capitalize:first-letter {
  text-transform:capitalize;
}

.big-button.fab {
  .icon {
    font-size: 32px;
    line-height: 47px;
  }
}

.ion-md-user-plus,
.ion-ios-user-plus {
  font-family: 'awaken';

  &:before {
    content: '\f054';
  }
}

.ion-md-fa-book,
.ion-ios-fa-book {
  font-family: 'awaken';
  content: '\f026';

  &:before {
    content: '\f026';
  }
}

.ion-md-user-o,
.ion-ios-user-o {
  font-family: 'awaken';

  &:before {
    content: '\f024';
  }
}

.awaken-fab {
  > button {
    margin:0 15px 15px 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .5);

    > ion-icon, i {
      font-weight: 400;
      font-size: 2.4rem;
    }
  }
  .fab-left {
    margin:0 0 15px 15px !important;
  }
}

.ion-md-user-o-outline,
.ion-ios-user-o-outline {
  font-family: 'awaken';

  &:before {
    content: '\f024';
  }
}

.ion-md-scale-bathroom,
.ion-ios-scale-bathroom {
  font-family: 'awaken';

  &:before {
    content: '\f025';
  }
}

a.tab-button {
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.ion-md-scale-bathroom-outline,
.ion-ios-scale-bathroom-outline {
  font-family: 'awaken';

  &:before {
    content: '\f025';
  }
}

.ion-md-fa-image,
.ion-ios-fa-image {
  font-family: 'awaken';

  &:before {
    content: '\f04d';
  }
}

.ion-md-fa-image-outline,
.ion-ios-fa-image-outline {
  font-family: 'awaken';

  &:before {
    content: '\f04d';
  }
}

.ion-md-attach-money,
.ion-ios-attach-money {
  font-family: 'awaken';

  &:before {
    content: '\f04b';
  }
}

.ion-md-attach-money-outline,
.ion-ios-attach-money-outline {
  font-family: 'awaken';

  &:before {
    content: '\f04b';
  }
}

.ion-md-lunch-outline,
.ion-ios-lunch-outline {
  font-family: 'awaken';

  &:before {
    content: '\f046';
  }
}

.ion-ios-lunch,
.ion-md-lunch {
  font-family: 'awaken';
  color: $blue;

  &:before {
    content: '\f046';
  }
}


.awcard {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 3px;
  color: rgba(0,0,0, 0.87);
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.button-inner {
  font-family: "open sans"
}

.flex-col {
  .button-inner {
    flex-direction: column;
  }
  flex-direction: column;
}



.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.bd-callout-warning {
  border-left-color: #f0ad4e;
}
.bd-callout-danger {
  border-left-color: $red;
}
.bd-callout-success {
  border-left-color: green;
}

// .ng-invalid + ion-label {
//   color:red;
// }

// .ng-invalid {
//   border: 3px solid $red;
// }

@keyframes fadeInLoad {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

.ios .ion-page.show-page ~ .nav-decor {
  background: transparent;
}

.icon-list {
  i.right {
    font-size:30px;
    position:absolute;
    top:10px;
    right: 20px;
  }
}

.loader.fadeMeIn {
  display: block;
  // animation: fadeInLoad 2s;
}

.loader {
  position:absolute;
  width:100%;
  height:100%;
  z-index:9999999999999;
  display:none;
}
.svg-favicon {
  transform-origin: center;
  //transform-box: fill-box;
  width: 400px;
  margin: 0 auto;
  transform: translateY(+50%);
  svg {
    overflow: visible;
  }
}

@keyframes draw {
  50% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw_better {
  from {}

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fade_in {
  from { fill-opacity: 0; }
  to { fill-opacity: 1; }
}

@keyframes scale {
  from { transform: scale(1.0) }
  to { transform: scale(0.9) }
}

#arc {
  transform-origin: center;
  transform-box: fill-box;
  fill-opacity: 0;
  animation: draw_better 2s 0s forwards, fade_in 2s 2s forwards, scale 1s ease-in-out 4s alternate infinite;
}

#letter {
  transform-box: fill-box;
  transform-origin: center;
  fill-opacity: 0;
  animation: draw_better 2s 0s forwards, fade_in 2s 2s forwards, scale 1s ease-in-out 4s alternate infinite;
}

@mixin mdb-label-color-toggle-focus(){
  // override bootstrap focus and keep all the standard color (could be multiple radios in the form group)
  .form-group.is-focused & {
    color: $mdb-label-color;

    // on focus just darken the specific labels, do not turn them to the brand-primary
    &:hover,
    &:focus {
      color: $mdb-label-color-toggle-focus;
    }

    // correct the above focus color for disabled items
    fieldset[disabled] & {
      color: $mdb-label-color;
    }
  }
}

.shaded-slider {
  .shade-me {
    box-shadow: rgba(0, 0, 0, 0.5) 5px 0px 31px 2px;
  }
}

.sliding-button {
  .button-inner { text-transform: none; }
}

.text-shadow {
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
}

.fort-awesome {
  content: "\f044";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  font-size: 400px;
  color: lightblue;
  top: 50%;
  left: 50%;
  margin: -300px 0 0 -200px;
  z-index: 1;
}


.form-horizontal {

  // Consistent vertical alignment of radios and checkboxes
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    padding-top: 0;
  }

  .radio {
    margin-bottom: 10px;
  }

  label {
    text-align: right;
  }

  label.control-label {
    margin: 0;
  }
}



.success.chart {
  stroke: green;
}

.remaining.chart {
  stroke: $blue;
}

.fab {
  contain: layout;
}

.right-caret {
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid #000000;
  display: inline-block;
  height: 0;
  opacity: 0.3;
  vertical-align: top;
  width: 0;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc][side-left]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 6px;
  background-color: var(--ion-color-primary);
  padding: 5px 9px;
  border-radius: 15px;
  color: white;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}

@mixin coloredLabel($color) {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  left: 55px;
  bottom: 6px;
  background-color: $color;
  padding: 5px 9px;
  border-radius: 15px;
  color: white;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}


ion-fab-button.labelOnRight[data-desc]::after {
  @include coloredLabel(var(--ion-color-primary))
}

ion-fab-button.labelOnRight.secondary[data-desc]::after,
ion-fab-button.labelOnRight.danger[data-desc]::after{
  @include coloredLabel(var(--ion-color-secondary))
}

ion-fab-button.labelOnRight.warning[data-desc] {
  i, ion-icon {
    color: black;
  }
  &::after {
    @include coloredLabel(var(--ion-color-warning));
    color: black;
  }
}

ion-fab-button.labelOnRight.yellow[data-desc] {
  i, ion-icon {
    color: black;
  }
  &::after {
    @include coloredLabel(var(--ion-color-yellow));
    color: black;
  }
}

button[ion-fab] {
  overflow: visible;
  position: relative;

  ion-label[left] {
    position: absolute;
    top: -8px;
    right: 40px;

    color: white;
    background-color: rgba(0,0,0,0.7);
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  ion-label[right] {
    position: absolute;
    top: -8px;
    right: 40px;

    color: white;
    background-color: rgba(0,0,0,0.7);
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.callout {
  padding: 1em;
  border-radius: 1em;

  &.primary { border: 1px solid $blue; }
  &.success { border: 1px solid $green; }
  &.danger { border: 1px solid $red; }
}

button[ion-fab] {
  overflow: visible;
  position: relative;

  ion-label[up-right] {
    position: absolute;
    top: -56px;
    left: 28px;
    transform: rotate(-35deg);
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.ct-label {
  color: white;
  fill: white;
  font-size: 1.25em;
  font-weight: 600;
}


.icon-shadow { text-shadow: 0 2px 2px rgba(0, 0, 0, .5) }
.simple-shadow { box-shadow: 0 2px 2px rgba(0, 0, 0, .5) }


ion-icon {
  //font-family: "Ionicons" !important;
}

@for $i from 10 through 100 {
  .fs-#{$i} {
    font-size: #{ $i / 10 }rem !important;
  }
}

@for $i from 1 through 100 {
  .width-#{$i} {
    width: #{percentage($i/100)}
  }
}

td.comment-cell {
  display: flex;
  flex-direction: row;
  max-height: 250px;
  flex-wrap: wrap;
  overflow-y: scroll;
}

[full-height] {
  height:100%;
}

ion-modal {
  border-radius: 0;

  .modal-wrapper {
    //border-radius: 0 !important;
    //position: absolute;
    //top: 20px;
    //left: 0;
    //display: block;
    //width: 100%;
    //height: 100%;
  }
}

.toast-container {
  max-height: 200px;
  overflow: scroll;
}

// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.small-inline-form {
  input {
    max-width: 100px;
    text-align: center;
  }
}

.font-weight-medium {
  font-weight: 500;
}

form label, ion-label {
  font-weight: 500;
}

.togglebutton {
  vertical-align: middle;
  &, label, input, .toggle {
    user-select: none;
  }
  label {
    cursor: pointer;
    color: $mdb-label-color;
    @include mdb-label-color-toggle-focus();

    // Hide original checkbox
    input[type=checkbox] {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .toggle {
      text-align: left; // Issue #737 horizontal form
      margin-left: 5px;
    }
    // Switch bg off and disabled
    .toggle,
    input[type=checkbox][disabled] + .toggle {
      content: "";
      display: inline-block;
      width: 30px;
      height: 15px;
      background-color: rgba(80, 80, 80, 0.7);
      border-radius: 15px;
      margin-right: 15px;
      transition: background 0.3s ease;
      vertical-align: middle;
    }
    // Handle off
    .toggle:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #FFFFFF;
      border-radius: 20px;
      border:1px solid rgba(0,0,0, .54);
      position: relative;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
      left: -5px;
      top: -3px;
      // border: 1px solid $mdb-checkbox-border-color;
      transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    }
    input[type=checkbox] {
      // Handle disabled
      &[disabled] {
        & + .toggle:after,
        &:checked + .toggle:after {
          background-color: #BDBDBD;
        }
      }

      & + .toggle:active:after,
      &[disabled] + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
      }

      // Ripple off and disabled
      &:checked + .toggle:after {
        left: 15px;
      }
    }

    // set bg when checked
    input[type=checkbox]:checked {
      + .toggle {
        background-color: rgba($brand-primary, (70/100)); // Switch bg on
      }

      + .toggle:after {
        border-color: $brand-primary; // Handle on
      }

      + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($brand-primary, (10/100)); // Ripple on
      }
    }
  }
}







//    Rest
* {
  //font-family: 'Open Sans', sans-serif;
  //font-size: 16px;
}

button.btn {font-size:16px;}
ion-slides.scroll-y .swiper-container { overflow-y: scroll; }

.logo-image {
  background-image: url("./assets/newred_newblue_outline.png")
}

[ion-fab] {
  i {
    font-size: 24px;
  }
}

.table.weigh_ins {
  td {
    line-height: 33px;
  }
}


.table[border-right-left] {
  tr td {
    border-right: 1px solid #eceeef;
    border-left: 1px solid #eceeef;
  }
}
.table[border-right] {
  tr td {
    border-right: 1px solid #eceeef;
  }
}

.contain-image {
  background-size: contain;
  background-repeat: no-repeat;

  &.center {
    background-position: center;
  }

  &.cover {
    background-size: cover;
  }
}

.smaller {
  font-size: 0.8em;
}

.inline-block {
  display: inline-block;
}

.list-md ion-item-options {
  border:none;
}

ion-item-sliding.br-1 {
  .list-md ion-item-options .button {
    border-radius:5px;
  }
}

.table tr td {
  font-size: 16px;
}

.full-width.card {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.red-bold {
  color: $red;
  font-weight: 700;

  transition: color 0.3s ease-in;
}

.bg-fade-blue {
  background: rgba(30, 114, 255, 0.07);
  transition: background 0.3s ease-in;
}

.card-row {
  ion-card {
    margin-left: 0;
    margin-right: 0;

  }
}


// ion-list#custom {
//   ion-item.item-block {
//     border-bottom: none;

//     item-inner {
//       border-bottom: 1px solid #dedede !important;
//     }

//     &:last-child {
//       item-inner {  border-bottom: 1px solid #dedede !important;}
//     }
//   }
// }

.shaded-slider {
  &:last-of-type {
    .item-inner { border: none; }
  }
}

ion-toolbar.gradient-fill {
  .toolbar-background {
    height: 100%;
    background-color: red;
    background-image:
            radial-gradient(
                            circle at bottom right,
                            purple, dodgerblue
            );
  }
  .toolbar-title { color: inherit; font-weight:500;}
}

ion-toolbar.blue.footer,
.blue.footer {
  color: #ddd;
  transition: all 0.3s ease-out;

  .active {
    color: white;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.3);
    span { font-weight: 700; }

    transition: all 0.3s ease-in;
  }

  .toolbar-title {
    color:inherit;
  }


  .toolbar-background-md {
    background-color: dodgerblue;
  }
}

.inline {
  display: inline;
}

button#blue-button {
  background-image: linear-gradient(top, #1e90ff, #0077ea);
  background-image: -webkit-linear-gradient(top, #1e90ff, #0077ea);
  background-image: -moz-linear-gradient(top, #1e90ff, #0077ea);
  background      : -webkit-linear-gradient(top, #1e90ff 0%, #0077ea 100%);
  background      : linear-gradient(to bottom, #1e90ff 0%, #0077ea 100%);
  filter          : progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e90ff', endColorstr='#005db7',GradientType=0 );
  color           : white;
}
.submit-form {
  background-image: linear-gradient(top, #1e90ff, #0077ea);
  background-image: -webkit-linear-gradient(top, #1e90ff, #0077ea);
  background-image: -moz-linear-gradient(top, #1e90ff, #0077ea);
  background: -webkit-linear-gradient(top, #1e90ff 0%, #0077ea 100%);
  background: linear-gradient(to bottom, #1e90ff 0%, #0077ea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e90ff', endColorstr='#005db7',GradientType=0 );
  color: white;
  width: 100%;
  font-size: 0.75em;
  font-weight: 300;
  line-height: 1.75em;
  border-radius: 0.5em;
  box-shadow: 0px 2px 7px -2px black;
  border: none;
  outline: none;
}

.submit-form:hover,
button#blue-button:hover {
  background-image: -webkit-linear-gradient(top, #0077ea, #1e90ff);
  background-image: linear-gradient(top, #0077ea, #1e90ff);
  background: -webkit-linear-gradient(top, #0077ea 0%, #1e90ff 100%);
  background: linear-gradient(to bottom, #0077ea 0%, #1e90ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005db7', endColorstr='#1e90ff',GradientType=0 );
}

[padding-5] {
  padding: 0.5em;
}

[padding-bottom-5] {
  padding-bottom: 0.5em;
}

// ion-list.inner-border-bottom {
//   ion-item {
//     .item-inner { border-bottom: 1px solid #dedede !important }
//     &:last-of-type {
//       .item-inner { border: none !important;}
//     }
//   }

// }

ion-list.grid-form {
  .item-input:last-child {
    border-bottom: none;
  }
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}



label.form-input{
  font-size: 1em;
  color:black;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1px;
  justify-content: flex-start;
  border: none;
}

.form-input {
  // width: 100%;
  text-indent: 0.5em;
  display: block;
  z-index: 1000;
  font-size: 1em;
  line-height: 1.75em;
  border: 1px solid dodgerblue;
  border-radius: 0.5em;
  outline: none;

  &:focus, &:active {
    border: 1px solid $red;
  }

  &[width-100] {
    width: 100%;
  }
}

.submit-form:active {
  -webkit-transform: translate(0, 2px);
  box-shadow: 0px 1px 2px 0px;
  text-decoration: none !important;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bg-gray {
  background: #ddd;
}

.item-text-wrap {
  overflow: initial;
  white-space: initial;
}

.animate {
  &.slide-down {

  }
}

.animate-if {
  background:white;
  border:1px solid black;
  padding:10px;
}

.animate-if.ng-enter, .animate-if.ng-leave {
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity:0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity:1;
}

.slideContent {
  overflow-y: hidden;
  max-height: 1500px; /* approximate max height */

  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  &.closed {
    max-height: 0;
    // overflow-y: hidden;
    // transition-property: all;
    // transition-duration: .5s;
    // transition-timing-function: ease-in-out;
  }
}

*:focus {
  outline: none !important;
}

#ion-diagnostics-toast {
  top: 9px;
}

.fill-height {
  height: 100vh;

  ion-row {
    flex-grow: 1;
    align-items: center;
  }

  &.center {
    ion-col { text-align: center; }
  }
}

.table.awaken-table {
  transition: all 0.2s linear;

  thead {
    tr {
      border-bottom: 3px solid dodgerblue;
      th {
        border: none;
        text-align: center;
        font-weight: 600;
        color: dodgerblue;
        padding-bottom: 0.5em;
      }
    }
  }

  tr {
    td.mini,
    th.mini {
      width: 1px;
      white-space: nowrap;
    }
  }

  &.borderless {
    tbody > tr > td { border: none; }
  }

  &.table-hover {
    > tbody > tr:hover	{
      background: rgba(255, 0, 0, 0.07);
      transition: all 0.2s linear;
    }
  }

  &.table-even {
    table-layout: fixed;
  }

  &.text-center {
    > tbody > tr > td { text-align: center; }
  }

  &.table-mini {
    width: 1px;
    tr > td {
      width: 1px;
      white-space: nowrap;
    }
  }

  &.total-row {
    tbody tr:last-of-type {
      border-top: 3px solid $blue;
    }
  }
}

*[stretch] {
  align-items: stretch;
}

*[full-height] {
  height: 100%;
}

.consult-fail {
  color: $red;
  text-decoration: line-through;
}

.consult-success {
  color: green;
  text-decoration: line-through;
}

.awaken-gradient {
  background-image: linear-gradient(-180deg, #E7292A 0%, #745294 50%, #4961BC 77%, #007BFF 100%);
}

.blue-purple-gradient {
  --background: radial-gradient(circle, rgba(0,114,240,1) 0%, rgba(134,0,255,1) 100%);
}


.grid-item {
  width: 50%;
}

.pckry-grid {

  [no-margin] { margin: 0; }

  .gutter-sizer { width: 1%; }

  .grid-item {
    background: transparent;
    float: left;
    width: 8.3333333333%;

    > div {
      background: white;
      margin: 5px;
      padding: 1em;
    }
  }

  @for $i from 1 to 13 {

    .g-xs-#{$i} {
      @media (min-width: 0px) and (max-width: 575px) {
        width: (8.33333333% * $i)
      }
    }

    .g-sm-#{$i} {
      @media (min-width: 576px) and (max-width: 767px) {
        width: (8.33333333% * $i)
      }
    }

    .g-md-#{$i} {
      @media (min-width: 768px) and (max-width: 991px) {
        width: (8.33333333% * $i)
      }
    }

    .g-lg-#{$i} {
      @media (min-width: 992px) {
        width: (8.33333333% * $i)
      }
    }
  }
}

.fw-600 {
  font-weight:600;
}

.line {
  border-bottom: 0.55px solid rgb(200, 199, 204);
  height: 1px;
}

ion-content.montserrat, .montserrat {
  * {
    font-family: 'Montserrat';
    //font-weight: 500;
  }
  strong, .bold {
    font-weight: 700;
  }
  ion-icon {
    font-family: 'ionicons';
    &.ion-ios-scale-bathroom  { font-family: 'awaken'; }
    &.ion-ios-scale-bathroom-outline { font-family: 'awaken'; }
    &.ion-ios-user-o  { font-family: 'awaken'; }
    &.ion-ios-user-o-outline { font-family: 'awaken'; }
    &.ion-ios-attach-money { font-family: 'awaken'; }
    &.ion-ios-attach-money-outline { font-family: 'awaken'; }
    &.ion-ios-fa-image { font-family: 'awaken'; }
    &.ion-ios-fa-image-outline { font-family: 'awaken'; }
  }
  i { font-family: 'Awaken'}
}

.group-selector-alert {
  .group-selector-radio {
    contain: unset;
    height: auto;

    .alert-radio-label {
      white-space: normal;
    }
  }
}

ion-content.quicksand, .quicksand {
  * {
    font-family: 'quicksand';
  }
  ion-icon {
    font-family: 'ionicons';
    &.ion-ios-scale-bathroom  { font-family: 'awaken'; }
    &.ion-ios-scale-bathroom-outline { font-family: 'awaken'; }
    &.ion-ios-user-o  { font-family: 'awaken'; }
    &.ion-ios-user-o-outline { font-family: 'awaken'; }
    &.ion-ios-attach-money { font-family: 'awaken'; }
    &.ion-ios-attach-money-outline { font-family: 'awaken'; }
    &.ion-ios-fa-image { font-family: 'awaken'; }
    &.ion-ios-fa-image-outline { font-family: 'awaken'; }
  }
  i { font-family: 'Awaken'}
}

ion-content.has-refresher > .scroll-content {
  background-color: transparent !important;
  border: none !important;
}

.list.list-ios[inset] {
  notification-item > button { border-bottom: 1px solid #c8c7cc !important; }
  notification-item:last-of-type > button { border: none !important; }
}

ion-title.dope-blue > .toolbar-title {
  color: $blue;
}

.bg-info-gradiant {
  --background: #188ef4;
  --background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
  background: #188ef4;
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.bg-purple-blue {
  --background: linear-gradient(-180deg, rgb(136, 64, 255) 20%, rgb(30, 144, 255) 100%);
  background: linear-gradient(-180deg, rgb(136, 64, 255) 20%, rgb(30, 144, 255) 100%);
}

.bg-success-gradiant {
  --background: #2cdd9b;
  --background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  background: #2cdd9b;
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}

.bg-danger-gradiant {
  --background: #ff4d7e;
  --background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  background: #ff4d7e;
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

.blue-red-gradient {
  --background: linear-gradient(-200deg, #30F3FF 0%, #FF1E1E 100%);
  background: linear-gradient(-200deg, #30F3FF 0%, #FF1E1E 100%);
}

ion-navbar[hidebackbutton] .back-button {
  display: none !important;
}

.toast-error {
  //--max-height: 400px;
  //overflow: scroll;
  //
  //.toast-content {
  //  max-height: 400px;
  //  overflow: scroll;
  //}
}

.pointer {
  cursor: pointer;
}

.list-table {
  .list-ios { margin-top: 0px; }

  .table-header {
    display: flex;
    font-weight: 600;
    color: $blue;
    border-bottom: 2px solid $blue;
  }

  .table-body {
    ion-list {
      width: 100%;
    }

    button.item.item-block {
      border-bottom: 1px solid #e9ecef;
      padding: 0;

      > .item-inner {
        padding: 0;
      }
    }

    ion-label {
      display: flex;
    }
  }
}

@for $i from 1 through 8 {
  .fw-#{$i * 100} {
    font-weight: $i * 100 !important;
  }
}

//h1,h2,h3,h4,h5,h6 {
//  font-family: 'Quicksand';
//}


//.css-grid {
//  display: grid;
//  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
//  grid-gap: 10px;
//  grid-auto-flow: dense;
//  //grid-auto-rows: 160px;
//
//
//  @for $i from 1 to 13 {
//
//    .g-#{i} {
//      grid-column: span $i
//    }
//
//    .g-xs-#{$i} {
//      @media (min-width: 0px) and (max-width: 575px) {
//        grid-column: span $i
//      }
//    }
//
//    .g-sm-#{$i} {
//      @media (min-width: 576px) and (max-width: 767px) {
//        grid-column: span $i
//      }
//    }
//
//    .g-md-#{$i} {
//      @media (min-width: 768px) and (max-width: 991px) {
//        grid-column: span $i
//      }
//    }
//
//    .g-lg-#{$i} {
//      @media (min-width: 992px) {
//        grid-column: span $i
//      }
//    }
//  }
//}
